import { useQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import {
  shortenAddress,
  useCall,
  useEthers,
  useLookupAddress,
} from "@usedapp/core";
import React, { useEffect, useState } from "react";

import { Body, Container, Header } from "./components";

//MUI
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Tooltip from "@mui/material/Tooltip";

import { SocialIcon } from "react-social-icons";

import { addresses, abis } from "@my-app/contracts";
import GET_TRANSFERS from "./graphql/subgraph";

import { Box, Grid } from "@mui/material";
import NavBar from "./components/NavBar";
import FAQ from "./components/FAQ";

//Images
import button_frame from "./images/button_frame.svg";
import cloud1 from "./images/clouds/cloud1.svg";
import cloud2 from "./images/clouds/cloud2.svg";
import whoAreWeCloud from "./images/clouds/who_are_we_cloud.svg";
import whoAreWeCloudEmpty from "./images/clouds/who_are_we_cloud_empty.svg";
import affirmationAppCloud from "./images/clouds/affirmation_app_cloud.svg";
import teamBioCloud from "./images/clouds/team_bio.svg";
import nuriFalling from "./images/nuris/nuri_falling.svg";
import nuriLanding from "./images/nuris/nuri_landing.svg";
import nuriLanded from "./images/nuris/nuri_landed.svg";
import nuriLREmpty from "./images/nuris/nuri_lowerright_empty.svg";
import bottomLandscape from "./images/nuris/bottom.svg";
import affirmationApp from "./images/affirmation_app.png";
import pawprintCloud1 from "./images/pawprint/pawprint_cloud1.svg";
import pawprintCloud2 from "./images/pawprint/pawprint_cloud2.svg";
import pawprintCloud3 from "./images/pawprint/pawprint_cloud3.svg";
import pawprintCloud4 from "./images/pawprint/pawprint_cloud4.svg";
import pawprintImage1 from "./images/pawprint/pawprint_image1.svg";
import pawprintImage2 from "./images/pawprint/pawprint_image2.svg";
import pawprintImage3 from "./images/pawprint/pawprint_image3.svg";
import pawprintImage4 from "./images/pawprint/pawprint_image4.svg";

import leafImage from "./images/leaf.svg";
import backdrop from "./images/sky_backdrop.png";
import backdropMobile from "./images/sky_backdrop_mobile.png";

import nuriLL from "./images/nuris/nuri_lowerleft.svg";
import nuriUL from "./images/nuris/nuri_upperleft.svg";
import nuriUR from "./images/nuris/nuri_upperright.svg";
import nuriLR from "./images/nuris/nuri_lowerright.svg";

import nuri1 from "./images/nuri_samples/1.png";
import nuri2 from "./images/nuri_samples/2.png";
import nuri3 from "./images/nuri_samples/3.png";
import nuri4 from "./images/nuri_samples/4.png";

import RickImage from "./images/Rick.PNG";
import MattImage from "./images/Matt.PNG";
import CynthiaImage from "./images/Cynthia.PNG";
import RitaImage from "./images/Rita.PNG";
import ProfessorImage from "./images/Professor.PNG";

//Routing
import { Routes, Route } from "react-router-dom";

//Pages
import Privacy from "./pages/privacy";
import GiftOrder from "./pages/giftOrder";

//popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

//gsap
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app-policy" element={<Privacy />} />
        <Route path="/gift" element={<GiftOrder />} />
      </Routes>
    </div>
  );
}

function Home() {
  // Read more about useDapp on https://usedapp.io/
  // const { error: contractCallError, value: tokenBalance } =
  //   useCall({
  //     contract: new Contract(addresses.ceaErc20, abis.erc20),
  //     method: "balanceOf",
  //     args: ["0x3f8CB69d9c0ED01923F11c829BaE4D9a4CB6c82C"],
  //   }) ?? {};

  // const { loading, error: subgraphQueryError, data } = useQuery(GET_TRANSFERS);

  // useEffect(() => {
  //   if (subgraphQueryError) {
  //     console.error(
  //       "Error while querying subgraph:",
  //       subgraphQueryError.message
  //     );
  //     return;
  //   }
  //   if (!loading && data && data.transfers) {
  //     console.log({ transfers: data.transfers });
  //   }
  // }, [loading, subgraphQueryError, data]);

  // Animation
  let t1 = new gsap.timeline();
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // smaller screens
      "(min-width: 0px) and (max-width: 600px)": function() {
        t1.to(".nuriUL", { x: 100 }, "Start")
          .to(".nuriLL", { x: 5 }, "Start")
          .to(".nuriLL", { y: 30 }, "Start")
          .to(".nuriUR", { x: 50 }, "Start")
          .to(".nuriUR", { y: -100 }, "Start");
        t1.duration(15);
        t1.repeat(-1)
          .yoyo(true)
          .play();
      },
      "(min-width: 600px) and (max-width: 900px)": function() {
        t1.to(".nuriUL", { x: 100 }, "Start")
          .to(".nuriLL", { x: 20 }, "Start")
          .to(".nuriUR", { x: 50 }, "Start")
          .to(".nuriUR", { y: -100 }, "Start");
        t1.duration(15);
        t1.repeat(-1)
          .yoyo(true)
          .play();
      },
      "(min-width: 900px)": function() {
        t1.to(".nuriUL", { x: 100 }, "Start")
          .to(".nuriLL", { x: 50 }, "Start")
          .to(".nuriUR", { x: 50 }, "Start")
          .to(".nuriUR", { y: -100 }, "Start");
        t1.duration(15);
        t1.repeat(-1)
          .yoyo(true)
          .play();
      },
    });
  });

  //Scroll effect for nuri falling
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // smaller screen
      "(max-width: 1200px)": function() {
        // The ScrollTriggers created inside these functions are segregated and get
        // reverted/killed when the media query doesn't match anymore.
        //hide original cloud
        gsap.to(".nuriLR", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 650px",
            end: "top 650px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //show empty cloud
        gsap.to(".nuriLREmpty", {
          opacity: 1,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 650px",
            end: "top 650px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //falling nuri opacity becomes 1
        gsap.to(".nuriFalling", {
          opacity: 1,
          ease: Power3,
          // duration: 1,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 650px",
            end: "top 650px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //nuri falls
        gsap.to(".nuriFalling", {
          y: 520,
          ease: Power3,
          // duration: 1,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 650px",
            end: "top 400px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //falling nuri opacity becomes 0
        gsap.to(".nuriFalling", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 520px",
            end: "top 520px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
        //landing nuri falls and lands
        gsap.to(".nuriLanding", {
          y: 1280,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 450px",
            end: "1000vh 80%",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
        gsap.to(".nuriLanding", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".whoAreWeCloud",
            start: "top 1000px",
            end: "top 1000px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
        gsap.to(".nuriLanded", {
          opacity: 1,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".whoAreWeCloud",
            start: "top 1000px",
            end: "top 1000px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
      },
      "(min-width: 1200px)": function() {
        //hide original cloud
        gsap.to(".nuriLR", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 600px",
            end: "top 600px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //show empty cloud
        gsap.to(".nuriLREmpty", {
          opacity: 1,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 600px",
            end: "top 600px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //falling nuri opacity becomes 1
        gsap.to(".nuriFalling", {
          opacity: 1,
          ease: Power3,
          // duration: 1,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 600px",
            end: "top 600px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //nuri falls
        gsap.to(".nuriFalling", {
          y: 600,
          ease: Power3,
          // duration: 1,
          scrollTrigger: {
            trigger: ".cloud1",
            start: "top 600px",
            end: "top 300px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });

        //falling nuri opacity becomes 0
        gsap.to(".nuriFalling", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloudParagraph",
            start: "top 600px",
            end: "top 600px",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
        //landing nuri falls and lands
        gsap.to(".nuriLanding", {
          y: 1280,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloudParagraph",
            start: "top 450px",
            end: "1000vh 80%",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
        gsap.to(".nuriLanding", {
          opacity: 0,
          ease: Power3,
          // duration: 3,
          scrollTrigger: {
            trigger: ".cloudParagraph",
            start: "880vh 80%",
            end: "880vh 85%",
            // markers: true,
            scrub: true,
            // toggleActions: "restart complete reverse restart",
          },
        });
      },
    });
  }, []);

  //Scroll effect for cloud parting
  useEffect(() => {
    gsap.to(".cloud1", {
      x: -720,
      opacity: 70,
      ease: Power3,
      duration: 3,
      scrollTrigger: {
        trigger: ".cloud1",
        start: "top 150px",
        end: "top -200px",
        // markers: true,
        scrub: true,
        // toggleActions: "restart complete reverse restart",
      },
    });
    gsap.to(".cloud2", {
      x: 700,
      opacity: 70,
      ease: Power3,
      duration: 3,
      scrollTrigger: {
        trigger: ".cloud1",
        start: "top 150px",
        end: "top -200px",
        // markers: true,
        scrub: true,
        // toggleActions: "restart complete reverse pause",
      },
    });
  }, []);
  return (
    // <Container>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: {
          xs: `url(${backdropMobile})`,
          md: `url(${backdrop})`,
        },
        backgroundRepeat: "no-repeat",
        // backgroundSize: "100% 100%",
        backgroundSize: { xs: "100% 100%", md: "cover" },
        // background: "linear-gradient(180deg, #eafafd 0%, #c4edf4 100%)",
      }}
    >
      <Header>
        {/* <WalletButton /> */}
        <NavBar />
      </Header>
      <Body>
        <Tooltip title="Back to Top">
          <img
            src={leafImage}
            className="upArrow"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            alt="Up Button"
          />
        </Tooltip>
        <div className="spacer-top"></div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
            justifyContent="center"
          >
            <Grid item xs={1} md={3}>
              <div className="cover_div">
                <img src={nuriUL} className="nuriUL" alt="Nuri" />
                <img src={nuriLL} className="nuriLL" alt="Nuri" />
              </div>
            </Grid>
            <Grid item xs={11} md={6}>
              <h2 className="subtitle">welcome to the</h2>
              <h1 className="mainTitle">nuri family</h1>
              <Box
                className="buttonFrame"
                sx={{
                  backgroundImage: `url(${button_frame})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginX: "auto",
                  marginTop: { xs: "-10px", md: "0vh" },
                  width: "300px",
                  height: "100px",
                }}
              >
                <p className="buttonText">Coming Soon!</p>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <div className="cover_div">
                <img src={nuriUR} className="nuriUR" alt="Nuri" />
                <img src={nuriLR} className="nuriLR" alt="Nuri" />
                <img src={nuriLREmpty} className="nuriLREmpty" alt="Nuri" />
                <img src={nuriFalling} className="nuriFalling" alt="Nuri" />
              </div>
            </Grid>
          </Grid>
        </Box>

        <div className="spacer-triple"></div>
        <div className="spacer"></div>
        <div id="nuri_explained">
          <img src={nuriLanding} className="nuriLanding" alt="Nuri" />
          <img src={cloud1} className="cloud1" alt="Cloud" />
          <img src={cloud2} className="cloud2" alt="Cloud" />
          <Box
            sx={{
              marginTop: { xs: "-140px", md: "30px" },
              width: { xs: "75%", sm: "70%", md: "50%" },
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              height: "550px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="cloudParagraph">
              Nuri = <span className="cloud_emphasis">"all is well" </span> in
              Korean. Nuri is also our pet bichon frise. With his
              clueless-smiling-fluffy hair he would always reassure us that “all
              is well.” Through Nuri Family, we want you to also have a Nuri who
              can remind you that in the end,{" "}
              <span className="cloud_emphasis">"all is well." </span>
            </p>
          </Box>
        </div>

        <div className="spacer"></div>
        <div className="whoAreWeContainer">
          <img className="nuriLanded" src={nuriLanded} alt="Nuri" />
          <img className="whoAreWeCloud" src={whoAreWeCloud} alt="Cloud" />
          <img
            className="whoAreWeCloudEmpty"
            src={whoAreWeCloudEmpty}
            alt="Cloud"
          />
          <div className="introParagraph" id="paragraph2">
            <div id="about"></div>
            <span className="whoAreWeTitle">Who are we?</span> <br />
            <br />
            Nuri Family is a collection of 10,000 clueless yet loving, fluffy
            friends living at the Ethereum Shelter. <br></br> Our mission is
            simple: <span className="introEmphasis">spread positivity</span>.
            You see the <FavoriteBorderIcon /> in their hands? That's for you!
            So what are you waiting for? Come adopt your Nuri and join the
            family!
            <Box
              className="buttonFrame"
              sx={{
                marginTop: {
                  xs: "0px",
                  sm: "50px",
                  md: "15px",
                  lg: "40px",
                },
                backgroundImage: `url(${button_frame})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginX: "auto",
                width: { xs: "220px", sm: "250px", md: "300px" },
                height: "100px",
              }}
            >
              <p className="buttonText">Coming Soon!</p>
            </Box>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: {
                xs: "150px",
                md: "135px",
                lg: "240px",
                xl: "220px",
              },
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                width: "80%",
                textAlign: "center",
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Grid item xs={3} sx={{ marginTop: { xs: "-100px" } }}>
                <img src={nuri1} className="nuriSample" alt="Nuri" />
              </Grid>
              <Grid item xs={3}>
                <img src={nuri2} className="nuriSample" alt="Nuri" />
              </Grid>
              <Grid item xs={3}>
                <img src={nuri3} className="nuriSample" alt="Nuri" />
              </Grid>
              <Grid item xs={3} sx={{ marginTop: { xs: "-100px" } }}>
                <img src={nuri4} className="nuriSample" alt="Nuri" />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: { xs: "-150px" },
            }}
          >
            <Grid
              container
              columnSpacing={0}
              sx={{
                width: "40%",
                textAlign: "center",
              }}
            >
              <Grid item xs={6} sx={{ marginTop: { xs: "60px", sm: "0px" } }}>
                <img src={nuri1} className="nuriSample" alt="Nuri" />
                <img src={nuri2} className="nuriSample" alt="Nuri" />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "100px" }}>
                <img src={nuri3} className="nuriSample" alt="Nuri" />
                <img src={nuri4} className="nuriSample" alt="Nuri" />
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="affirmationAppContainer">
          <img
            id="application"
            className="affirmationAppCloud"
            src={affirmationAppCloud}
            alt="Cloud"
          />
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: { xs: "50%", md: "60%", lg: "56%", xl: "56%" },
              left: "50%",
              transform: "translate(-50%, -40%)",
              width: "70%",
            }}
          >
            <Grid container width={"100%"}>
              <Grid item xs={6} md={8} lg={6}>
                <h3 className="affirmationTitle">Affirmation App</h3>
                <p className="affirmationP">
                  To accomplish our mission:
                  <br></br>
                  <span className="affirmationEmphasis">spread positivity</span>
                  <br></br>– we go way beyond an NFT collection. Starting with
                  our very own affirmation app. We are one of the first pioneers
                  to connect a mental health app with the NFT world!
                </p>
                <Box
                  className="buttonFrame"
                  sx={{
                    backgroundImage: `url(${button_frame})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    backgroundPosition: "center center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "300px",
                    height: "100px",
                  }}
                >
                  <p className="buttonText">Coming Soon!</p>
                </Box>{" "}
              </Grid>
              <Grid item xs={6} md={4} lg={6}>
                <img
                  src={affirmationApp}
                  className="affirmationAppImg"
                  alt="App"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              position: "absolute",
              top: { xs: "50%", md: "60%", lg: "60%", xl: "56%" },
              left: "50%",
              transform: "translate(-50%, -20%)",
              width: "80vw",
            }}
          >
            <h3 className="affirmationTitle">Affirmation App</h3>
            <p className="affirmationP">
              To accomplish our mission:
              <br></br>
              <span className="affirmationEmphasis">spread positivity</span>
              <br></br>– we go way beyond an NFT collection. Starting with our
              very own affirmation app. We are one of the first pioneers to
              connect a mental health app with the NFT world!
            </p>
            <Box
              className="buttonFrame"
              sx={{
                backgroundImage: `url(${button_frame})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginX: "auto",
                width: "300px",
                height: "100px",
              }}
            >
              <p className="buttonText">Coming Soon!</p>
            </Box>{" "}
            <img src={affirmationApp} className="affirmationAppImg" alt="App" />
          </Box>
        </div>

        <div className="spacer-triple"></div>
        <div id="pawprint">
          <h3 className="pawprintTitle">Pawprint</h3>
          <Grid
            container
            width={"100vw"}
            maxWidth={"1600px"}
            display={{ xs: "none", md: "flex" }}
          >
            <Grid item xs={6}>
              <div className="pawprintCloudContainer">
                <img
                  className="pawprintCloud1"
                  src={pawprintCloud1}
                  alt="Cloud"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "20%", lg: "25%" },
                    left: "60%",
                    transform: "translate(-50%, -20%)",
                    width: "60%",
                  }}
                >
                  <img
                    className="pawprintImage1"
                    src={pawprintImage1}
                    alt="Pawprint"
                  />
                  <p className="pawprintSubtitle">1. Pre-Adopt</p>
                  <p className="pawprintP">
                    4,000 Nuris will be available for early adoption!
                    <br></br>(+100 will be adopted by the team)
                  </p>
                </Box>
              </div>
              <div className="pawprintCloudContainer">
                <img
                  className="pawprintCloud3"
                  src={pawprintCloud3}
                  alt="Cloud"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "25%", lg: "12%", xl: "5%" },
                    left: "65%",
                    transform: "translate(-50%, -56%)",
                    zIndex: 3,
                    width: "50%",
                  }}
                >
                  <img
                    className="pawprintImage3"
                    src={pawprintImage3}
                    alt="Pawprint"
                  />
                  <p className="pawprintSubtitle">3. Affirmation App Launch</p>
                  <p className="pawprintP">
                    The portal opens... Grow alongside your Nuris!
                  </p>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="pawprintCloudContainer">
                <img
                  className="pawprintCloud2"
                  src={pawprintCloud2}
                  alt="Cloud"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: { xs: "60%", lg: "50%" },
                    transform: "translate(-50%, -50%)",
                    zIndex: 2,
                    width: "60%",
                  }}
                >
                  <img
                    className="pawprintImage2"
                    src={pawprintImage2}
                    alt="Pawprint"
                  />
                  <p className="pawprintSubtitle">2. Public-Adopt</p>
                  <p className="pawprintP">
                    The rest of the Nuris will be waiting for public adoption!
                  </p>
                </Box>
              </div>
              <div className="pawprintCloudContainer">
                <img
                  className="pawprintCloud4"
                  src={pawprintCloud4}
                  alt="Cloud"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "12%", lg: "6%", xl: "3%" },
                    left: "55%",
                    transform: "translate(-55%, -40%)",
                    zIndex: 4,
                    width: "80%",
                  }}
                >
                  <img
                    className="pawprintImage4"
                    src={pawprintImage4}
                    alt="Pawprint"
                  />
                  <p className="pawprintSubtitle">4. Family Secret Recipe</p>
                  <p className="pawprintP">
                    You thought we would stop at the app? Nah, don’t worry. Once
                    a family member, always a family member. Nuri holders will
                    also have access to the Secret Family Recipe...
                  </p>
                </Box>
              </div>
            </Grid>
          </Grid>

          <Box display={{ xs: "block", md: "none" }}>
            <div className="pawprintCloudContainer">
              <img
                className="pawprintCloud1"
                src={pawprintCloud1}
                alt="Cloud"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: "17%", lg: "25%" },
                  left: "50%",
                  transform: "translate(-50%, -10%)",
                  width: "62vw",
                }}
              >
                <img
                  className="pawprintImage1"
                  src={pawprintImage1}
                  alt="Pawprint"
                />
                <p className="pawprintSubtitle">1. Pre-Adopt</p>
                <p className="pawprintP">
                  4,000 Nuris will be available for early adoption!
                  <br></br>(+100 will be adopted by the team)
                </p>
              </Box>
            </div>
            <div className="pawprintCloudContainer">
              <img
                className="pawprintCloud2"
                src={pawprintCloud2}
                alt="Cloud"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  left: "52%",
                  transform: "translate(-50%, -90%)",
                  zIndex: 2,
                  width: "62vw",
                }}
              >
                <img
                  className="pawprintImage2"
                  src={pawprintImage2}
                  alt="Pawprint"
                />
                <p className="pawprintSubtitle">2. Public-Adopt</p>
                <p className="pawprintP">
                  The rest of the Nuris will be waiting for public adoption!
                </p>
              </Box>
            </div>

            <div className="pawprintCloudContainer">
              <img
                className="pawprintCloud3"
                src={pawprintCloud3}
                alt="Cloud"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: { xs: "52%", sm: "60%" },
                  transform: "translate(-50%, -95%)",
                  zIndex: 3,
                  width: "65vw",
                }}
              >
                <img
                  className="pawprintImage3"
                  src={pawprintImage3}
                  alt="Pawprint"
                />
                <p className="pawprintSubtitle">3. Affirmation App Launch</p>
                <p className="pawprintP">
                  The portal opens... Grow alongside your Nuris!
                </p>
              </Box>
            </div>

            <div className="pawprintCloudContainer">
              <img
                className="pawprintCloud4"
                src={pawprintCloud4}
                alt="Cloud"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "7%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 4,

                  width: "70vw",
                }}
              >
                <img
                  className="pawprintImage4"
                  src={pawprintImage4}
                  alt="Pawprint"
                />
                <p className="pawprintSubtitle">4. Family Secret Recipe</p>
                <p className="pawprintP">
                  You thought we would stop at the app? Nah, don’t worry. Once a
                  family member, always a family member. Nuri holders will also
                  have access to the Secret Family Recipe...
                </p>
              </Box>
            </div>
          </Box>
        </div>
        <div className="spacer_to_faq"></div>

        <div id="faq">
          <h3 className="faqTitle">FAQ</h3>

          <FAQ></FAQ>
        </div>

        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="team">
          <h3 className="teamTitle">Team & Our Story</h3>
          <Grid
            container
            columnSpacing={1}
            sx={{
              width: "85%",
              textAlign: "center",
              display: "flex",
              marginX: "auto",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} md={3} paddingBottom={"50px"}>
              <Popup
                modal
                trigger={
                  <img src={MattImage} className="bioImage" alt="Team" />
                }
                className="bioPopup"
              >
                {(close) => (
                  <div className="bioCloudContainer">
                    <img
                      src={teamBioCloud}
                      className="bioCloud"
                      alt="TeamCloud"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "52%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "75vw", md: "25vw" },
                        minWidth: { xs: "270px", md: "500px" },
                        maxWidth: { xs: "500px" },
                      }}
                    >
                      <div className="bioCloudContent">
                        <img
                          src={MattImage}
                          className="bioImageInCloud"
                          alt="Team"
                        />
                        <div className="bioLabel">
                          <p className="teamNameInCloud">Matthew Kim</p>
                          <SocialIcon
                            url="https://twitter.com/matt_kimbo"
                            className="bioSocial1"
                            style={{ height: 30, width: 30 }}
                          />
                          <SocialIcon
                            url="https://www.linkedin.com/in/matthew-kwanwoo-kim-428344180/"
                            className="bioSocial"
                            style={{ height: 30, width: 30 }}
                          />
                        </div>
                        <p className="teamBioInCloud">
                          <span className="bioEmphasis">
                            Matthew is the co-founder and community manager of
                            Nuri Family.
                          </span>{" "}
                          He is a student at Washington University in St. Louis
                          studying Cognitive Neuroscience. After experiencing
                          verbal violence in the South Korean military, his
                          mission became to build a place where people can share
                          positive words. He is happy to say that that place
                          today…is Nuri Family.
                        </p>
                        <Box
                          className="buttonFrame"
                          sx={{
                            backgroundImage: `url(${button_frame})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundPosition: "center center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginX: { xs: "0px", md: "auto" },
                            width: "240px",
                            height: "100px",
                          }}
                          onClick={() => {
                            close();
                          }}
                        >
                          <p className="buttonText">Close</p>
                        </Box>
                      </div>
                    </Box>
                  </div>
                )}
              </Popup>
              <p className="teamName">Matthew Kim</p>
              <p className="teamBio">Co-Founder & Community Manager</p>
            </Grid>
            <Grid item xs={6} md={3} paddingBottom={"50px"}>
              <Popup
                modal
                trigger={
                  <img src={RickImage} className="bioImage" alt="Team" />
                }
                className="bioPopup"
              >
                {(close) => (
                  <div className="bioCloudContainer">
                    <img
                      src={teamBioCloud}
                      className="bioCloud"
                      alt="TeamCloud"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "52%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "75vw", md: "25vw" },
                        minWidth: { xs: "270px", md: "500px" },
                        maxWidth: { xs: "500px" },
                      }}
                    >
                      <img
                        src={RickImage}
                        className="bioImageInCloud"
                        alt="Team"
                      />
                      <div className="bioLabel">
                        <p className="teamNameInCloud">Rick Liu</p>
                        <SocialIcon
                          url="https://twitter.com/_rickliu"
                          className="bioSocial1"
                          style={{ height: 30, width: 30 }}
                        />
                        <SocialIcon
                          url="https://linkedin.com/in/rick-l"
                          className="bioSocial"
                          style={{ height: 30, width: 30 }}
                        />
                      </div>
                      <p className="teamBioInCloud">
                        <span className="bioEmphasis">
                          Rick is the co-founder and developer of Nuri Family.
                        </span>{" "}
                        He recently graduated from Washington University in St.
                        Louis with a Bachelor’s Degree in Business and Computer
                        Science. He is also the founder and host of{" "}
                        <a
                          href="https://www.seedstartupjourney.com/"
                          className="bioLink"
                        >
                          The Seed
                        </a>
                        , a podcast in which he explored the startup journeys of
                        entrepreneurs including the founders of Square,
                        T-Mobile, The Sandbox, and more! His passion towards
                        mental health, NFT, and tech helped build the technology
                        and vision behind the Nuri Family we see today.{" "}
                      </p>
                      <Box
                        className="buttonFrame"
                        sx={{
                          backgroundImage: `url(${button_frame})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",
                          backgroundPosition: "center center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginX: "auto",
                          width: "240px",
                          height: "100px",
                        }}
                        onClick={() => {
                          close();
                        }}
                      >
                        <p className="buttonText">Close</p>
                      </Box>
                    </Box>
                  </div>
                )}
              </Popup>
              <p className="teamName">Rick Liu</p>
              <p className="teamBio">Co-Founder & Developer</p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={1}
            sx={{
              width: "85%",
              textAlign: "center",
              display: "flex",
              marginX: "auto",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} md={3}>
              <Popup
                modal
                trigger={
                  <img src={RitaImage} className="bioImage" alt="Team" />
                }
                className="bioPopup"
              >
                {(close) => (
                  <div className="bioCloudContainer">
                    <img
                      src={teamBioCloud}
                      className="bioCloud"
                      alt="TeamCloud"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "75vw", md: "25vw" },
                        minWidth: { xs: "270px", md: "500px" },
                        maxWidth: { xs: "500px" },
                      }}
                    >
                      <img
                        src={RitaImage}
                        className="bioImageInCloud"
                        alt="Team"
                      />
                      <div className="bioLabel">
                        <p className="teamNameInCloud">Rita Cat</p>
                        <SocialIcon
                          url="https://twitter.com/catmadecom"
                          className="bioSocial1"
                          style={{ height: 30, width: 30 }}
                        />
                      </div>
                      <p className="teamBioInCloud">
                        <span className="bioEmphasis">
                          Rita Cat is an artist who loves creating colorful and
                          positive art.
                        </span>{" "}
                        From drawing, knitting, sculpting, ceramics to handmade
                        crafts, she uses art to express her feelings. She
                        believes in art therapy which helps her push through
                        challenges. She is also a huge lover of animals. She has
                        3 cats (two of her babies passed away a few months ago –
                        Mr Cat, her best friend, and Wolf). Now she lives with
                        her one kitty named Busya.{" "}
                      </p>
                      <Box
                        className="buttonFrame"
                        sx={{
                          backgroundImage: `url(${button_frame})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",
                          backgroundPosition: "center center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginX: "auto",
                          width: "240px",
                          height: "100px",
                        }}
                        onClick={() => {
                          close();
                        }}
                      >
                        <p className="buttonText">Close</p>
                      </Box>
                    </Box>
                  </div>
                )}
              </Popup>
              <p className="teamName">Rita Cat</p>
              <p className="teamBio">Cat Lover & Artist</p>
            </Grid>

            <Grid item xs={6} md={3}>
              <Popup
                modal
                trigger={
                  <img src={CynthiaImage} className="bioImage" alt="Team" />
                }
                className="bioPopup"
              >
                {(close) => (
                  <div className="bioCloudContainer">
                    <img
                      src={teamBioCloud}
                      className="bioCloud"
                      alt="TeamCloud"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "75vw", md: "25vw" },
                        minWidth: { xs: "270px", md: "500px" },
                        maxWidth: { xs: "500px" },
                      }}
                    >
                      <img
                        src={CynthiaImage}
                        className="bioImageInCloud"
                        alt="Team"
                      />
                      <div className="bioLabel">
                        <p className="teamNameInCloud">Cynthia Liu</p>
                        <SocialIcon
                          url="https://www.linkedin.com/in/hsinyaliu/"
                          className="bioSocial1"
                          style={{ height: 30, width: 30 }}
                        />
                      </div>
                      <p className="teamBioInCloud">
                        <span className="bioEmphasis">
                          Cynthia is an UI/UX Designer who tells stories through
                          her visual designs.
                        </span>{" "}
                        She has a deep passion for mental health and is
                        currently working for the mental health startup Safe
                        Space. Cynthia also loves to travel and explore the
                        wondrous cuisines all over the world ✈️
                      </p>
                      <Box
                        className="buttonFrame"
                        sx={{
                          backgroundImage: `url(${button_frame})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",
                          backgroundPosition: "center center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginX: "auto",
                          width: "240px",
                          height: "100px",
                        }}
                        onClick={() => {
                          close();
                        }}
                      >
                        <p className="buttonText">Close</p>
                      </Box>
                    </Box>
                  </div>
                )}
              </Popup>
              <p className="teamName">Cynthia Liu</p>
              <p className="teamBio">UI/UX Designer</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <Popup
                modal
                trigger={
                  <img src={ProfessorImage} className="bioImage" alt="Team" />
                }
                className="bioPopup"
              >
                {(close) => (
                  <div className="bioCloudContainer">
                    <div className="bioCloudFlex">
                      <img
                        src={teamBioCloud}
                        className="bioCloud"
                        alt="TeamCloud"
                      />
                    </div>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: { xs: "45%", md: "50%" },
                        transform: "translate(-50%, -50%)",
                        width: { xs: "75vw", md: "25vw" },
                        minWidth: { xs: "270px", md: "500px" },
                        maxWidth: { xs: "500px" },
                      }}
                    >
                      <img
                        src={ProfessorImage}
                        className="bioImageInCloud"
                        alt="Team"
                      />
                      <div className="bioLabel">
                        <p className="teamNameInCloud">Dr. Tim Bono</p>
                        <SocialIcon
                          url="https://artsci.wustl.edu/faculty-staff/tim-bono"
                          className="bioSocial1"
                          style={{ height: 30, width: 30 }}
                        />
                      </div>
                      <p className="teamBioInCloud">
                        <span className="bioEmphasis">
                          Dr. Tim Bono is the expert advisor of the Nuri Family.
                        </span>{" "}
                        He is a lecturer at Washington University in St. Louis
                        and thousands of students have taken his popular course
                        on the the Science of Happiness. He is an expert
                        consultant on psychological health and happiness for a
                        number of national media outlets, including{" "}
                        <em>CNN, Fast Company, The Associated Press</em>. He is
                        also the author of the book,{" "}
                        <a
                          href="https://www.amazon.com/When-Likes-Arent-Enough-Happiness/dp/1538743426"
                          className="bioLink"
                        >
                          Happiness 101
                        </a>
                        . He offers the Nuri Family perspectives on mental
                        health resources that are healthy and scientifically
                        supported.
                      </p>
                      <Box
                        className="buttonFrame"
                        sx={{
                          backgroundImage: `url(${button_frame})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",
                          backgroundPosition: "center center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginX: "auto",
                          width: "240px",
                          height: "100px",
                        }}
                        onClick={() => {
                          close();
                        }}
                      >
                        <p className="buttonText">Close</p>
                      </Box>
                    </Box>
                  </div>
                )}
              </Popup>
              <p className="teamName">Dr. Tim Bono</p>
              <p className="teamBio">Expert Advisor</p>
            </Grid>
          </Grid>
        </div>
        <img
          className="bottomLandscape"
          src={bottomLandscape}
          alt="Background"
        />
      </Body>
    </Box>
  );
}

export default App;
