//https://mui.com/components/app-bar/
// import * as React from "react";
import MenuIcon from "../images/menuIcon.png";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
  Drawer,
  List,
  Divider,
  ListItem,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";

import discord from "../images/navbar/discord.svg";
import twitter from "../images/navbar/twitter.svg";

import {
  shortenAddress,
  useCall,
  useEthers,
  useLookupAddress,
} from "@usedapp/core";
import React, { useEffect, useState } from "react";

import button_frame from "../images/button_frame.svg";

import { SocialIcon } from "react-social-icons";

//Navigation
import { HashLink as Link } from "react-router-hash-link";

const pages = ["About Us", "Affirmation App", "Pawprint", "FAQ", "Team"];
//Linking each page to the route path. Back to home for now
let pagesLink = {};
pagesLink[pages[0]] = "/#about";
pagesLink[pages[1]] = "/#application";
pagesLink[pages[2]] = "/#pawprint";
pagesLink[pages[3]] = "/#faq";
pagesLink[pages[4]] = "/#team";

//Scroll offset function for Link
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -180;
  if (el.id === "about") {
    //Only offsetting for Who are we section
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  } else {
    window.scrollTo({ top: yCoordinate, behavior: "smooth" });
  }
};

function WalletButton() {
  const [rendered, setRendered] = useState("");

  const ens = useLookupAddress();
  const { account, activateBrowserWallet, deactivate, error } = useEthers();

  useEffect(() => {
    if (ens && account) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);

  useEffect(() => {
    if (error) {
      console.error("Error while connecting wallet:", error.message);
    }
  }, [error]);

  return (
    // <ButtonForWallet
    <Box
      className="buttonFrame"
      sx={{
        backgroundImage: `url(${button_frame})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginX: "auto",
        // width: "200px",
        paddingX: "25px",
        minWidth: "120px",
        maxWidth: "180px",
        height: "100px",
      }}
      onClick={() => {
        if (!account) {
          activateBrowserWallet();
        } else {
          deactivate();
          setRendered("");
        }
      }}
    >
      <p className="walletButtonText">
        {rendered === "" && "Connect Wallet"}
        {rendered !== "" && rendered}
      </p>
    </Box>
  );
}

//Passing in variables needed for wallet
function NavBar() {
  return (
    // <AppBar position="static" sx={{ bgcolor: "#eafafd" }} elevation={0}>
    <AppBar position="static" sx={{ bgcolor: "transparent" }} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 2,
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {pages.map((page) => {
              return (
                <Link
                  smooth
                  to={pagesLink[page]}
                  className="menuLink"
                  key={page}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  <Button
                    key={page}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      textTransform: "none",
                    }}
                    style={{
                      fontFamily: "Bryndan Write",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "23px",
                      color: "#5599A5",
                    }}
                  >
                    {page}
                  </Button>
                </Link>
              );
              // }
            })}
          </Box>
          <Box
            className="navbarSocials"
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              paddingLeft: "1vw",
              paddingTop: "20px",
            }}
          >
            <Tooltip title="Coming Soon!">
              <div className="navbarSocialsIndiv">
                {/* <a href="http://google.com"> */}
                <img src={discord} className="img-normal" alt="Discord" />
                {/* </a> */}
              </div>
            </Tooltip>
            <div className="navbarSocialsIndiv">
              <a href="https://twitter.com/NuriFamily">
                <img src={twitter} className="img-normal" alt="Twitter" />
              </a>
            </div>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <MobileMenu WalletButton={<WalletButton />} />
          </Box>
          <Box
            sx={{
              // flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <WalletButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function MobileMenu({ WalletButton }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  //Tooltip for socials
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((title) => (
          <ListItem key={title}>
            <Link
              to={pagesLink[title]}
              scroll={(el) => scrollWithOffset(el)}
              className="menuLinkMobile"
            >
              <Button
                // href={pagesLink[title]}
                sx={{ width: "100%" }}
                onClick={toggleDrawer("right", false)}
              >
                <Typography
                  sx={{
                    my: 1,
                    textTransform: "none",
                    fontFamily: "Nunito, sans-serif",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#93a2a9",
                  }}
                >
                  {title}
                </Typography>
              </Button>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <>{WalletButton}</>
      </Box>
      <Box
        className="navbarSocials"
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: "1vw",
          paddingTop: "20px",
        }}
      >
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title="Coming Soon!"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
          >
            <div className="navbarSocialsIndiv">
              {/* <a href="http://google.com"> */}
              <img
                src={discord}
                className="img-normal"
                alt="Discord"
                onClick={handleTooltipOpen}
              />
              {/* </a> */}
            </div>
          </Tooltip>
        </ClickAwayListener>

        <div className="navbarSocialsIndiv">
          <a href="https://twitter.com/NuriFamily">
            <img src={twitter} className="img-normal" alt="Twitter" />
          </a>
        </div>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer("right", true)}
        sx={{
          color: "black",
          justifyContent: "right",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={MenuIcon} alt="Menu" style={{ width: "50%" }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
}
export default NavBar;
