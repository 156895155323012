import * as React from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";

import faqCloud1 from "../images/clouds/faq_cloud1.svg";
import faqCloud2 from "../images/clouds/faq_cloud2.svg";
import faqCloud3 from "../images/clouds/faq_cloud3.svg";
import faqCloud4 from "../images/clouds/faq_cloud4.svg";
import faqDropDownDesktop from "../images/clouds/faq_dropdown.svg";
import faqDropDownDesktopLong from "../images/clouds/faq_scroll_desktop_long.svg";
import faqDropDownMobile from "../images/clouds/faq_scroll_mobile_short.svg";
import faqDropDownMobileLong from "../images/clouds/faq_scroll_mobile_long.svg";

import NuriImage from "../images/nuri_bichon.png";

export default function FAQ() {
  const [qOneOpen, setQOneOpen] = React.useState(true);
  const [qTwoOpen, setQTwoOpen] = React.useState(false);
  const [qThreeOpen, setQThreeOpen] = React.useState(false);
  const [qFourOpen, setQFourOpen] = React.useState(false);

  const handleClick = (collapseID) => {
    switch (collapseID) {
      case "q1":
        setQOneOpen(!qOneOpen);
        break;
      case "q2":
        setQTwoOpen(!qTwoOpen);
        break;
      case "q3":
        setQThreeOpen(!qThreeOpen);
        break;
      case "q4":
        setQFourOpen(!qFourOpen);
        break;
      default:
        break;
    }
  };

  return (
    <List
      sx={{
        width: "80%",
        display: "block",
        marginX: "auto",
        maxWidth: "700px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Box
        sx={{ position: "relative" }}
        onClick={() => {
          handleClick("q1");
        }}
      >
        <img src={faqCloud1} className="faq_cloud" alt="Cloud" />
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30%", md: "40%", lg: "50%", xl: "56%" },
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <h2 className="faqSubtitle">Pre-Adopt?</h2>
            <Box sx={{ marginY: "auto" }}>
              {qOneOpen ? (
                <ExpandLess sx={{ color: "black" }} />
              ) : (
                <ExpandMore sx={{ color: "black" }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "-5vh",
          backgroundImage: {
            xs: `url(${faqDropDownMobile})`,
            md: `url(${faqDropDownDesktop})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          display: "block",
          width: "100%",
        }}
      >
        <Collapse
          sx={{
            marginTop: { xs: "-75px", md: "-100px" },
            paddingTop: "80px",
            paddingBottom: "120px",
          }}
          in={qOneOpen}
          timeout="auto"
          unmountOnExit
        >
          <div className="FAQText">
            <p>
              <span className="faqEmphasis">Wen?</span> TBD
            </p>
            <p>
              <span className="faqEmphasis">Price?</span> TBD
            </p>
            <p>
              <span className="faqEmphasis">How many?</span> 4,000 Nuris will be
              available for early adoption! (+100 will be adopted by the team)
            </p>
            <p>
              <span className="faqEmphasis">Max adoption?</span> 2 per wallet
            </p>
          </div>
        </Collapse>
      </Box>

      <div className="spacer-faq"></div>

      <Box
        sx={{ position: "relative" }}
        onClick={() => {
          handleClick("q2");
        }}
      >
        <img src={faqCloud2} className="faq_cloud" alt="Cloud" />
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30%", md: "40%", lg: "50%", xl: "56%" },
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <h2 className="faqSubtitle">Public-Adopt?</h2>
            <Box sx={{ marginY: "auto" }}>
              {qTwoOpen ? (
                <ExpandLess sx={{ color: "black" }} />
              ) : (
                <ExpandMore sx={{ color: "black" }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "-5vh",
          backgroundImage: {
            xs: `url(${faqDropDownMobile})`,
            md: `url(${faqDropDownDesktop})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          display: "block",
          width: "100%",
        }}
      >
        <Collapse
          sx={{
            marginTop: { xs: "-75px", md: "-100px" },
            paddingTop: "80px",
            paddingBottom: "120px",
          }}
          in={qTwoOpen}
          timeout="auto"
          unmountOnExit
        >
          <div className="FAQText">
            <p>
              <span className="faqEmphasis">Wen?</span> TBD
            </p>
            <p>
              <span className="faqEmphasis">Price?</span> TBD
            </p>
            <p>
              <span className="faqEmphasis">How many?</span> The rest of the
              Nuris after Early Adoption will be available for public!
            </p>
            <p>
              <span className="faqEmphasis">Max adoption?</span> 3 per wallet
            </p>
          </div>
        </Collapse>
      </Box>

      <div className="spacer-faq"></div>

      <Box
        sx={{ position: "relative" }}
        onClick={() => {
          handleClick("q3");
        }}
      >
        <img src={faqCloud3} className="faq_cloud" alt="Cloud" />
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30%", md: "40%", lg: "50%", xl: "56%" },
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <h2 className="faqSubtitle">Affirmation App?</h2>
            <Box sx={{ marginY: "auto" }}>
              {qThreeOpen ? (
                <ExpandLess sx={{ color: "black" }} />
              ) : (
                <ExpandMore sx={{ color: "black" }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "-5vh",
          backgroundImage: {
            xs: `url(${faqDropDownMobileLong})`,
            md: `url(${faqDropDownDesktopLong})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          display: "block",
          width: "100%",
        }}
      >
        <Collapse
          in={qThreeOpen}
          timeout="auto"
          unmountOnExit
          sx={{
            marginTop: { xs: "-85px", md: "-100px" },
            paddingTop: "80px",
            paddingX: { xs: "10px" },
            paddingBottom: { xs: "200px", md: "180px" },
          }}
        >
          <div className="FAQText">
            <p>
              <span className="faqEmphasis">How does it work?</span> <br></br>
              Words are <span className="introHighlight">powerful</span>,
              Empower yourself with positive affirmations throughout the day.
              <br></br>
              <br></br>
              And what’s better is that you aren’t alone. Your Nuris will be
              portaled into the app, so they can be with you every step of your
              positivity journey!
              <br></br>
              <br></br>
              BONUS: The more you use the app, the more points you can get. And
              with those points, you can donate real-life meals and toys to our
              fluffy friends over at the{" "}
              <a href="https://apamo.org/" className="bioLink">
                APA Adoption Center!
              </a>
            </p>
            <p>
              <span className="faqEmphasis">Wen?</span> <br></br>The beta
              version of our app will be available after the adoption of Nuris!
            </p>
          </div>
        </Collapse>
      </Box>

      <div className="spacer-faq"></div>
      <Box
        sx={{ position: "relative" }}
        onClick={() => {
          handleClick("q4");
        }}
      >
        <img src={faqCloud4} className="faq_cloud" alt="Cloud" />
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30%", md: "40%", lg: "50%", xl: "56%" },
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <h2 className="faqSubtitle">Wait... what is Nuri again?</h2>
            <Box sx={{ marginY: "auto" }}>
              {qFourOpen ? (
                <ExpandLess sx={{ color: "black" }} />
              ) : (
                <ExpandMore sx={{ color: "black" }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "-5vh",
          backgroundImage: {
            xs: `url(${faqDropDownMobileLong})`,
            md: `url(${faqDropDownDesktopLong})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          display: "block",
          width: "100%",
        }}
      >
        <Collapse
          in={qFourOpen}
          timeout="auto"
          unmountOnExit
          sx={{
            marginTop: { xs: "-75px", md: "-100px" },
            paddingTop: "80px",
            paddingX: "20px",
            paddingBottom: "170px",
          }}
        >
          <div className="FAQText">
            <p>
              Nuri = <span className="faqEmphasis">“all is well”</span> in
              Korean. Nuri is also our pet bichon frise. Everyday, no matter if
              it rains or shines, he would always use his fluffiness and
              heartwarming smile to remind us that “all is well.”
              <br /> Through Nuri Family, we want you to also have your own Nuri
              who can remind you that at the end, “all is well!”
            </p>
            <img src={NuriImage} className="nuri_image" alt="Nuri" />
          </div>
        </Collapse>
      </Box>
    </List>
  );
}
