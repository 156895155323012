import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Widget } from "@typeform/embed-react";

import { Body, Container, Header } from "../components";

export default function GiftOrder() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(180deg, #eafafd 0%, #c4edf4 100%)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "80%",
          marginX: "auto",
          minHeight: "100vh",
        }}
      >
        <h1 className="mainTitle" style={{ fontSize: 60, textAlign: "center" }}>
          Order a Gift!
        </h1>
        <Widget
          id="UQhxGEuD"
          style={{ width: "100%", height: "80vh", minHeight: "500px" }}
          className="my-form"
        />
      </Box>
    </Box>
  );
}
