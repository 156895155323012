import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { Body, Container, Header } from "../components";

export default function Privacy() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(180deg, #eafafd 0%, #c4edf4 100%)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "80%",
          marginX: "auto",
        }}
      >
        <h1 className="privacyTitle">PRIVACY NOTICE</h1>
        <p className="privacyP">
          <b>Last updated July 08, 2022</b>
        </p>
        <p className="privacyP">
          This privacy notice for NURINANA LLC (doing business as Nuri Family)
          ("Nuri Family," "we," "us," or "our"), describes how and why we might
          collect, store, use, and/or share ("process") your information when
          you use our services ("Services"), such as when you:
          <ul>
            <li>
              Download and use our mobile application (Nuri), or any other
              application of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <br></br>
          <b>Questions or concerns?</b> Reading this privacy notice will help
          you understand your privacy rights and choices. If you do not agree
          with our policies and practices, please do not use our Services. If
          you still have any questions or concerns, please contact us at
          hello@nurifamily.io.
        </p>
        <br></br>
        <h1 className="privacySubtitle">1. WHAT INFORMATION DO WE COLLECT?</h1>
        <p className="privacyP">
          We collect personal information that you voluntarily provide to us
          when you express an interest in obtaining information about us or our
          products and Services, when you participate in activities on the
          Services, or otherwise when you contact us.
        </p>
        <p className="privacyP">
          <b>Personal Information Provided by You.</b> The personal information
          that we collect depends on the context of your interactions with us
          and the Services, the choices you make, and the products and features
          you use. The personal information we collect may include the
          following:
          <ul>
            <li>email addresses</li>
          </ul>
        </p>
        <p className="privacyP">
          <b>Sensitive Information.</b> We do not process sensitive information.
        </p>
        <p className="privacyP">
          <b>Application Data.</b> If you use our application(s), we also may
          collect the following information if you choose to provide us with
          access or permission:
          <ul>
            <li>
              <i>Mobile Device Data.</i> We automatically collect device
              information (such as your mobile device ID, model, and
              manufacturer), operating system, version information and system
              configuration information, device and application identification
              numbers, browser type and version, hardware model Internet service
              provider and/or mobile carrier, and Internet Protocol (IP) address
              (or proxy server). If you are using our application(s), we may
              also collect information about the phone network associated with
              your mobile device, your mobile device’s operating system or
              platform, the type of mobile device you use, your mobile device’s
              unique device ID, and information about the features of our
              application(s) you accessed.
            </li>
            <li>
              <i>Push Notifications.</i> We may request to send you push
              notifications regarding your account or certain features of the
              application(s). If you wish to opt out from receiving these types
              of communications, you may turn them off in your device's
              settings.
            </li>
          </ul>
          <br></br>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes. All personal information
          that you provide to us must be true, complete, and accurate, and you
          must notify us of any changes to such personal information.
        </p>
        <p className="privacyP">
          <b>Information automatically collected</b> We automatically collect
          certain information when you visit, use, or navigate the Services.
          This information does not reveal your specific identity (like your
          name or contact information) but may include device and usage
          information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when
          you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes. Like many businesses, we also collect information through
          cookies and similar technologies. The information we collect includes:
          <ul>
            <li>
              <b>Log and Usage Data.</b> Log and usage data is service-related,
              diagnostic, usage, and performance information our servers
              automatically collect when you access or use our Services and
              which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in
              the Services (such as the date/time stamps associated with your
              usage, pages and files viewed, searches, and other actions you
              take such as which features you use), device event information
              (such as system activity, error reports (sometimes called "crash
              dumps"), and hardware settings).
            </li>
            <li>
              <b>Device Data.</b> We collect device data such as information
              about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
            </li>
            <li>
              <b>Location Data.</b> We collect location data such as information
              about your device's location, which can be either precise or
              imprecise. How much information we collect depends on the type and
              settings of the device you use to access the Services. For
              example, we may use GPS and other technologies to collect
              geolocation data that tells us your current location (based on
              your IP address). You can opt out of allowing us to collect this
              information either by refusing access to the information or by
              disabling your Location setting on your device. However, if you
              choose to opt out, you may not be able to use certain aspects of
              the Services.
            </li>
          </ul>
        </p>
        <br></br>
        <h1 className="privacySubtitle">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h1>
        <p className="privacyP">
          <b>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </b>{" "}
          <ul>
            <li>
              <b>
                To deliver and facilitate delivery of services to the user.{" "}
              </b>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <b>To respond to user inquiries/offer support to users. </b> We
              may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              <b>To fulfill and manage your orders. </b> We may process your
              information to fulfill and manage your orders, payments, returns,
              and exchanges made through the Services.
            </li>
            <li>
              <b>To save or protect an individual's vital interest. </b> We may
              process your information when necessary to save or protect an
              individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
        </p>
        <br></br>

        <h1 className="privacySubtitle">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h1>
        <p className="privacyP">
          <b>Vendors, Consultants, and Other Third-Party Service Providers.</b>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that
          work. We have contracts in place with our third parties, which are
          designed to help safeguard your personal information. This means that
          they cannot do anything with your personal information unless we have
          instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct. The categories of third parties we may share
          personal information with are as follows:
          <ul>
            <li>Data Analytics Services</li>
            <li>Data Storage Service Providers</li>
            <li>Performance Monitoring Tools</li>
          </ul>
          We also may need to share your personal information in the following
          situations:
          <ul>
            <li>
              <b>Business Transfers. </b> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <b>Business Partners</b> We may share your information with our
              business partners to offer you certain products, services, or
              promotions.
            </li>
          </ul>
        </p>

        <br></br>
        <h1 className="privacySubtitle">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h1>
        <p className="privacyP">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <br></br>
        <h1 className="privacySubtitle">
          5. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h1>
        <p className="privacyP">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than 90
          days.
          <br></br>
          <br></br>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <br></br>
        <h1 className="privacySubtitle">
          6. HOW DO WE KEEP YOUR INFORMATION SAFE?{" "}
        </h1>
        <p className="privacyP">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <br></br>
        <h1 className="privacySubtitle">
          7. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
        </h1>
        <p className="privacyP">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <br></br>
        <h1 className="privacySubtitle">
          8. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
        </h1>
        <p className="privacyP">
          If you have questions or comments about this notice, you may email us
          at hello@nurifamily.io
        </p>
      </Box>
    </Box>
  );
}
